/* CONTAINER 1 - HOME */
.container01_home_flex{
   display: flex;
   align-items: center;
   justify-content: center;
   padding-top: 80px;
}

.IA_container01{
    width: 90%;
}

#contaienr01_home__h2{
    color: #7751e5;
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    padding-bottom: 15px;
}

#container01_home__p{
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}
.container01_home_ul__p{
    color: #7751e5;
    font-weight: 600;
    font-size: 19px;
}
.flutuar {
    animation: flutuar 2s ease-in-out infinite;
  }
  
  @keyframes flutuar {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-15px);
    }
    100% {
      transform: translatey(0px);
    }
  }  

  /* CONTAINER 02 - HOME */

  .container02_home_flex{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
 }
 
  .cidade_iot_container02{
    width: 100%;
    padding-left: 50px;
  }

  #container02_home__h2{
    color: #7751e5;
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    padding-bottom: 15px;
}

.p__servicos{
    color: #7751e5;
    font-weight: 600;
    font-size: 19px;
}

 /* CONTAINER 03 - HOME */

 .container03_home{
    padding-top: 10rem;
    /* background-color: #14141e; */
    background-image: url('../../assets/images/new_weria/imagens_background_container03.png');
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    align-items: center;
    height: 85rem;
 }

 #container03_home__h1{
    padding-top: 130px;
    padding-bottom: 9rem;
    align-items: center;
    justify-content: center;
 }


 /* CONTAINER 04 - HOME */

 .container04_home_flex{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10rem;
    padding-bottom: 10rem ;
 }

 .imagemCerebro_container04{
    width: 90%;
    position: relative;
}

 #contaienr04_home__h2{
    color: #7751e5;
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    padding-bottom: 15px;
}

.procentagem{
    color: #7751e5;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #ddd;
    border-radius: 5px;
    margin-bottom: 5.3rem;
  }
  
  .progress {
    height: 100%;
    width: 95%;
    background-color: #7450e4;
    animation: progress-animation 4s;
    border-radius: 5px;
  }
  .progress02 {
    height: 100%;
    width: 90%;
    background-color: #7450e4;
    animation: progress-animation02 4s;
    border-radius: 5px;
  }
  .progress03 {
    height: 100%;
    width: 92%;
    background-color: #7450e4;
    animation: progress-animation03 4s;
    border-radius: 5px;
  }
  
  .progress04 {
    height: 100%;
    width: 94%;
    background-color: #7450e4;
    animation: progress-animation04 4s;
    border-radius: 5px;
  }
  
  /* @keyframes progress-animation {
    from {
      width: 0%;
    }
    to {
      width: 95%;
    }
  }
  @keyframes progress-animation02 {
    from {
      width: 0%;
    }
    to {
      width: 90%;
    }
  }
  @keyframes progress-animation03 {
    from {
      width: 0%;
    }
    to {
      width: 92%;
    }
  }
  @keyframes progress-animation04 {
    from {
      width: 0%;
    }
    to {
      width: 94%;
    }
  } */


/* PÁGINA DE PRODUTOS */


#container01_produtos__h1{
padding-top: 7rem;
}

.linha {
    height: 3px;
    width: 40%;
    background-color: #7250e2;
    position: relative;
    animation: move 4s linear infinite;
  }
  
  @keyframes move {
    0% {
      left: 0;
    }
    100% {
      left: 100%;
      right: 30%;
    }
  }

  #container01_produtos__text{
    padding-bottom: 7rem;
  }

  #container02_produtos_h2__ia__iot{
    padding-top: 1.5rem;
  }

  #linha__inferior{
    margin-bottom: 7rem;
  }


  @media (max-width: 800px) {
    #container02_pai_produtos{
      flex-direction: column !important;
    }
  }
  
/* PÁGINA POLÍTICA DE PRIVACIDADE */

#container_01_politica_priv{
padding-top: 2rem;
}

.h1_politica_priv{
  color: #7250e2;
}

.itens__p__politica_priv{
  color: #7250e2;
  font-weight: 600;
}

.date_edit_politica_priv{
  font-weight: 700;
}

/* PÁGINA DE SOBRE NOS | NOSSO TIME */

.container01_sobreNos_pai{
  padding-bottom: 7rem;
}

#contaienr01_sobreNos__h3{
  color: #7250e2;
padding-top: 7rem;
}

#container03_sobreNos_pai{
  padding-top: 7rem;
  padding-bottom: 7rem;
  display: flex;
  justify-content: center;
}

#titulo_subtitutlo_lucas{
  padding-left: 15px;
  padding-right: 15px;
}

#text_lucas{
  padding-left: 15px;
  padding-right: 15px;
}

.card {
	position: relative;
	z-index: 1;
	display: grid;
	place-content: center;
	width: 280px;
	height: 400px;
	/* color: #a18a96; */
	text-align: center;
	background: url('../../assets/images/new_weria/imagem-user.png') center no-repeat;
	background-size: cover;
	padding: 10px;
	border-radius: 20px;
	overflow: hidden;
}
.card::before,
.card::after {
	content: '';
	position: absolute;
	left: 0;
	z-index: -1;
	width: 100%;
	height: calc(60% + 60px);
	background-color: #7751e5;
	transition: transform 0.5s 0.25s;
}
.card::before {
	top: 0;
	clip-path: polygon(0 0, 100% 0, 100% 45%, 0% 100%);
	transform: translateY(-100%);
}
.card::after {
	bottom: 0;
	clip-path: polygon(0 55%, 100% 0, 100% 100%, 0% 100%);
	transform: translateY(100%);
}
.card__body {
	opacity: 0;
	transition: opacity 0.25s;
}
.card__title {
	color: #ffffff;
  font-size: 25px;
}

.card_subtitle{
  margin-bottom: 15px
}

.card:hover::before,
.card:hover::after {
	transform: translateY(0);
}
.card:hover .card__body {
	opacity: 1;
	transition-delay: 0.75s;
}


/* PÁGINA NOSSA EMPRESA */

.container01_nossa_empresa{
  padding-top: 15rem;
}

.container02_nossa_empresa{
  padding-top: 7rem;
  padding-bottom: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#container02_nossa_empresa__indice_number, #container02_nossa_empresa__texto_indice{
  color: #7250e2;
}

#card_carrossel{
  background-color: black;
}


.progress_automacao {
  height: 100%;
  width: 98%;
  background-color: #7450e4;
  animation: progress-animation03 4s;
  border-radius: 5px;
}

.progress_gerenciamento {
  height: 100%;
  width: 93%;
  background-color: #7450e4;
  animation: progress-animation03 4s;
  border-radius: 5px;
}

.progress_IA {
  height: 100%;
  width: 99%;
  background-color: #7450e4;
  animation: progress-animation03 4s;
  border-radius: 5px;
}

#container_parceiros{
  background-color: #7250e2;
}

.h1_parceiros{
  padding-bottom: 3rem;
  color: #ffffff;
}


/* PÁGINA DE CONTATO */

#container01_contato{
  padding-bottom: 7rem;
}